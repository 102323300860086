import axios from "axios"

export const searchYoutube = async (query, nextPageToken) => {
    return axios.get('', {
        baseURL: process.env.REACT_APP_YOUTUBE_SEARCH_API,
        params: {
            query,
            nextPageToken
        }
    }).then(r=>r.data)
}