import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/query-client";
import YoutubeSearch from "./YoutubeSearch";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <YoutubeSearch/>
    </QueryClientProvider>
  );
}

export default App;
