import React, { useState } from "react";
import { useSearchYoutube } from "./hooks/useSearchYoutube";
import { useDebounce } from "use-debounce";

export default function YoutubeSearch() {
  const [text, setText] = useState("Karaoke Mỹ tâm");
  const [value] = useDebounce(text, 1000);

  const { data, hasNextPage, fetchNextPage, isLoading } = useSearchYoutube({
    query: value,
  });

  return (
    <div>
      <input
      style={{marginBottom: 20}}
        defaultValue={"Karaoke Mỹ tâm"}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
      {(data?.pages || []).map((page) => {
        return page.data.videos.map((v) => {
          return <div key={v.videoId} style={{width: '100%', float:'left', marginBottom: 10}}>
              <img src={v.thumbnail.thumbnails[0].url} alt="thumb" style={{width: 120, height: 67, float:'left'}}></img>
              <div style={{width: 'calc(100% - 120px)', float:'left', paddingLeft: 10, boxSizing: 'border-box'}}>{v.title.runs[0].text}</div>
          </div>;
        });
      })}
      {hasNextPage && (
        <div onClick={fetchNextPage}>
          {isLoading ? "Loading more" : "Load more"}
        </div>
      )}
    </div>
  );
}
