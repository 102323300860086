import { useInfiniteQuery } from 'react-query'
import { searchYoutube } from '../services/youtube-search'

export const useSearchYoutube = ({query}) => {
    return useInfiniteQuery(['YOUTUBE_RESULT', query], async ({pageParam}) => {
        const data = await searchYoutube(query, pageParam);
        console.log(data)
        if (data?.statusCode === 200) {
            return data;
        }
        return {
            data: {
                videos: []
            }
        };
    }, {
        getNextPageParam: (lastPage) => {
            return lastPage?.data?.nextPageToken ?? false
        },
      })
}